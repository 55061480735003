define("discourse/plugins/discourse-brightcove/discourse/components/modal/brightcove-upload", ["exports", "@ember/component", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "I18n", "@ember/template-factory"], function (_exports, _component, _object, _ajax, _ajaxError, _decorators, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{i18n "brightcove.modal_title"}}
    @subtitle={{i18n "brightcove.modal_subtitle"}}
    class="brightcove-upload-modal"
    @closeModal={{@closeModal}}
  >
    <h3>{{i18n "brightcove.file"}}</h3>
    <p>
      {{#if this.file}}{{this.fileName}} ({{this.fileSize}}){{/if}}
      <label
        class="btn"
        disabled={{this.uploading}}
        title={{i18n "brightcove.select_file"}}
      >
        {{d-icon "film"}}&nbsp;{{i18n "brightcove.select_file"}}
        <input
          disabled={{this.uploading}}
          type="file"
          style="visibility: hidden; position: absolute;"
          {{on "change" (action "fileChanged")}}
        />
      </label>
    </p>
  
    <h3>{{i18n "brightcove.title"}}</h3>
    <p>
      <Input @value={{this.videoName}} disabled={{this.uploading}} />
    </p>
  
    {{#if this.uploading}}
      {{this.uploadProgress}}
    {{else}}
      <DButton
        @action={{this.upload}}
        @icon="upload"
        @label="upload"
        @disabled={{this.uploadDisabled}}
      />
    {{/if}}
  </DModal>
  */
  {
    "id": "DHeX4NhC",
    "block": "[[[8,[39,0],[[24,0,\"brightcove-upload-modal\"]],[[\"@title\",\"@subtitle\",\"@closeModal\"],[[28,[37,1],[\"brightcove.modal_title\"],null],[28,[37,1],[\"brightcove.modal_subtitle\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,1],[\"brightcove.file\"],null]],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    \"],[41,[30,0,[\"file\"]],[[[1,[30,0,[\"fileName\"]]],[1,\" (\"],[1,[30,0,[\"fileSize\"]]],[1,\")\"]],[]],null],[1,\"\\n    \"],[10,\"label\"],[14,0,\"btn\"],[15,\"disabled\",[30,0,[\"uploading\"]]],[15,\"title\",[28,[37,1],[\"brightcove.select_file\"],null]],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"film\"],null]],[1,\" \"],[1,[28,[35,1],[\"brightcove.select_file\"],null]],[1,\"\\n      \"],[11,\"input\"],[16,\"disabled\",[30,0,[\"uploading\"]]],[24,5,\"visibility: hidden; position: absolute;\"],[24,4,\"file\"],[4,[38,4],[\"change\",[28,[37,5],[[30,0],\"fileChanged\"],null]],null],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"h3\"],[12],[1,[28,[35,1],[\"brightcove.title\"],null]],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    \"],[8,[39,6],[[16,\"disabled\",[30,0,[\"uploading\"]]]],[[\"@value\"],[[30,0,[\"videoName\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"uploading\"]],[[[1,\"    \"],[1,[30,0,[\"uploadProgress\"]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,7],null,[[\"@action\",\"@icon\",\"@label\",\"@disabled\"],[[30,0,[\"upload\"]],\"upload\",\"upload\",[30,0,[\"uploadDisabled\"]]]],null],[1,\"\\n\"]],[]]]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"if\",\"d-icon\",\"on\",\"action\",\"input\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-brightcove/discourse/components/modal/brightcove-upload.hbs",
    "isStrictMode": false
  });
  const Evaporate = window.Evaporate;
  const SparkMD5 = window.SparkMD5;
  class BrightcoveUpload extends _component.default {
    file = null;
    init() {
      super.init(...arguments);
      this.set("file", this.model?.file);
    }
    fileName(file) {
      return file.name;
    }
    static #_ = (() => dt7948.n(this.prototype, "fileName", [(0, _decorators.default)("file")]))();
    fileSize(file) {
      return this.humanFilesize(file.size);
    }
    static #_2 = (() => dt7948.n(this.prototype, "fileSize", [(0, _decorators.default)("file")]))();
    humanFilesize(size) {
      const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + " " + ["B", "kB", "MB", "GB", "TB"][i];
    }
    setProgress(key, args) {
      this.set("uploadProgress", _I18n.default.t(`brightcove.upload_progress.${key}`, args));
    }
    createVideoObject() {
      this.set("uploading", true);
      this.setProgress("preparing");
      (0, _ajax.ajax)("/brightcove/create", {
        type: "POST",
        data: {
          name: this.get("videoName"),
          filename: this.get("fileName")
        }
      }).then(videoInfo => {
        this.setupEvaporate(videoInfo);
      }).catch(reason => {
        // eslint-disable-next-line no-console
        console.error("Could not create brightcove video.", reason);
        this.setProgress("error");
        (0, _ajaxError.popupAjaxError)(reason);
      });
    }
    setupEvaporate(videoInfo) {
      this.setProgress("starting");
      this.set("videoInfo", videoInfo);
      const config = {
        bucket: videoInfo["bucket"],
        aws_key: videoInfo["access_key_id"],
        signerUrl: `/brightcove/sign/${videoInfo["video_id"]}.json`,
        computeContentMd5: true,
        cryptoMd5Method: function (data) {
          return btoa(SparkMD5.ArrayBuffer.hash(data, true));
        },
        cryptoHexEncodedHash256: function (data) {
          return sha256(data);
        },
        logging: false
      };
      Evaporate.create(config).then(evaporate => {
        this.startEvaporateUpload(evaporate);
      }).catch(reason => {
        // eslint-disable-next-line no-console
        console.error("Brightcove failed to initialize. Reason: ", reason);
        this.setProgress("error");
      });
    }
    startEvaporateUpload(evaporate) {
      this.setProgress("uploading");
      const videoInfo = this.get("videoInfo");
      const headers = {
        "X-Amz-Security-Token": videoInfo["session_token"]
      };
      const add_config = {
        name: videoInfo["object_key"],
        file: this.get("file"),
        progress: progressValue => {
          this.setProgress("uploading", {
            progress: (progressValue * 100).toFixed(1)
          });
        },
        xAmzHeadersAtInitiate: headers,
        xAmzHeadersCommon: headers
      };
      evaporate.add(add_config).then(() => {
        this.ingestVideo();
      }).catch(reason => {
        // eslint-disable-next-line no-console
        console.error("Brightcove upload failed. Reason: ", reason);
        this.setProgress("error");
      });
    }
    ingestVideo() {
      this.setProgress("finishing");
      const videoInfo = this.get("videoInfo");
      (0, _ajax.ajax)(`/brightcove/ingest/${videoInfo["video_id"]}`, {
        type: "POST"
      }).then(() => {
        this.ingestComplete();
      }).catch(error => {
        // eslint-disable-next-line no-console
        console.error("Failed to ingest. Reason: ", error);
        this.setProgress("error");
      });
    }
    ingestComplete() {
      const videoInfo = this.get("videoInfo");
      this.setProgress("complete", {
        info: `[video=${videoInfo["video_id"]}]`
      });
      this.appEvents.trigger("composer:insert-text", `[video=${videoInfo["video_id"]}]`);
      this.closeModal();
    }
    uploadDisabled(file, videoName) {
      return !(file && videoName);
    }
    static #_3 = (() => dt7948.n(this.prototype, "uploadDisabled", [(0, _decorators.default)("file", "videoName")]))();
    fileChanged(event) {
      // eslint-disable-next-line no-console
      console.log("File Changed", event.target.files[0]);
      const file = event.target.files[0];
      this.set("file", file);
    }
    static #_4 = (() => dt7948.n(this.prototype, "fileChanged", [_object.action]))();
    upload() {
      this.createVideoObject();
    }
    static #_5 = (() => dt7948.n(this.prototype, "upload", [_object.action]))();
  }

  // SHA256 algorithm from https://github.com/jbt/js-crypto/blob/master/sha256.js
  /*eslint-disable */
  _exports.default = BrightcoveUpload;
  const sha256 = function () {
    // Eratosthenes seive to find primes up to 311 for magic constants. This is why SHA256 is better than SHA1
    var i = 1,
      j,
      K = [],
      H = [];
    while (++i < 18) {
      for (j = i * i; j < 312; j += i) {
        K[j] = 1;
      }
    }
    function x(num, root) {
      return Math.pow(num, 1 / root) % 1 * 4294967296 | 0;
    }
    for (i = 1, j = 0; i < 313;) {
      if (!K[++i]) {
        H[j] = x(i, 2);
        K[j++] = x(i, 3);
      }
    }
    function S(X, n) {
      return X >>> n | X << 32 - n;
    }
    function SHA256(b) {
      var HASH = H.slice(i = 0),
        s = unescape(encodeURI(b)) /* encode as utf8 */,
        W = [],
        l = s.length,
        m = [],
        a,
        y,
        z;
      for (; i < l;) m[i >> 2] |= (s.charCodeAt(i) & 0xff) << 8 * (3 - i++ % 4);
      l *= 8;
      m[l >> 5] |= 0x80 << 24 - l % 32;
      m[z = l + 64 >> 5 | 15] = l;
      for (i = 0; i < z; i += 16) {
        a = HASH.slice(j = 0, 8);
        for (; j < 64; a[4] += y) {
          if (j < 16) {
            W[j] = m[j + i];
          } else {
            W[j] = (S(y = W[j - 2], 17) ^ S(y, 19) ^ y >>> 10) + (W[j - 7] | 0) + (S(y = W[j - 15], 7) ^ S(y, 18) ^ y >>> 3) + (W[j - 16] | 0);
          }
          a.unshift((y = (a.pop() + (S(b = a[4], 6) ^ S(b, 11) ^ S(b, 25)) + ((b & a[5] ^ ~b & a[6]) + K[j]) | 0) + (W[j++] | 0)) + (S(l = a[0], 2) ^ S(l, 13) ^ S(l, 22)) + (l & a[1] ^ a[1] & a[2] ^ a[2] & l));
        }
        for (j = 8; j--;) HASH[j] = a[j] + HASH[j];
      }
      for (s = ""; j < 63;) s += (HASH[++j >> 3] >> 4 * (7 - j % 8) & 15).toString(16);
      return s;
    }
    return SHA256;
  }();
  /*eslint-enable */
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BrightcoveUpload);
});